import axios from 'axios';

let header = {
    "Content-Type": "application/json",
    'Access-Control-Allow-Origin': 'https://solutionops.cl',
    'Access-Control-Allow-Credentials': true,
    "x-api-key": ""
};

const functionCustom = () => {

  let url = ``;


  return axios.get(url, {
            headers: header
         });
};

const exportedObject = {
     functionCustom
};

export default exportedObject;
